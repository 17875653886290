import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
// material
import { Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import twitterFilled from '@iconify/icons-ant-design/twitter';
import youtubeFill from '@iconify/icons-ant-design/youtube-filled';
import facebookFilled from '@iconify/icons-eva/facebook-fill';
import Logo from '../../components/Logo';
import telegramFill from '@iconify/icons-ic/outline-telegram';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'Youtube', icon: youtubeFill, link: 'https://www.youtube.com/@examheist/videos?sub_confirmation=1' },
  { name: 'Telegram', icon: telegramFill, link: 'https://t.me/examheist_en' },
  // {
  //   name: 'Linkedin',
  //   icon: linkedinFill,
  //   link: 'https://www.linkedin.com/company/certyiq-company/posts/?feedView=all'
  // },
  // { name: 'Twitter', icon: twitterFilled, link: 'https://twitter.com/certyiq_' },
  // { name: 'Facebook', icon: facebookFilled, link: 'https://www.facebook.com/certyiq' }
];

const LINKS = [
  {
    headline: 'Exam Heist',
    children: [
      { name: 'Premium Access', href: PATH_PAGE.paymentmethod },
      { name: 'New Exam Request', href: PATH_PAGE.request },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'Feedback', href: PATH_PAGE.feedback }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacy },
      { name: 'About us', href: PATH_PAGE.about },
      // { name: 'Plan', href: PATH_PAGE.plan },
      { name: 'FAQs', href: PATH_PAGE.faqs }
    ]
  },
  {
    headline: 'Contact',
    children: [
      // { name: 'Exam Heist' },
      { name: 'support@examheist.com' },
      // { name: '+91 7206300476' },
      // { name: 'Dinod Gate, Bhiwani, Haryana, India' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              We are the biggest and most updated IT certification exam material website.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <a href={social?.link} target="_blank">
                  <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                </a>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) =>
                      link.href ? (
                        <Link
                          to={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <Link key={link.name} color="inherit" variant="body2" sx={{ display: 'block' }}>
                          {link.name}
                        </Link>
                      )
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
